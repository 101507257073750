import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Context from "./Context/Context";
import Maintenance from "./pages/Maintenance/Maintenance";
import Layouts from "./components/Layouts/Layouts";
import Home from "./pages/Home/Home";
import "dayjs/locale/fr";
import dayjs from "dayjs";
dayjs.locale("fr");
function App() {
  return (
    <Context>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layouts />}>
            <Route path="" element={<Home />} />
            <Route path="/maintenance" element={<Maintenance />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Context>
  );
}

export default App;
