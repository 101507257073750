import React from "react";
import { Col, Row } from "reactstrap";
import maintenanceIcon from "../../assets/images/iconMaintenance.png";
import classes from "./Maintenance.module.css";

const Maintenance = () => {
  return (
    <div className={classes.section}>
      <Row className={classes.container}>
        <Col className={classes.maintenance}>Plateforme en maintenance</Col>
        <Col>
          <img className={classes.image} alt="icon" src={maintenanceIcon} />
        </Col>
        <Col className={classes.disponiblite}>bientôt disponible</Col>
      </Row>
    </div>
  );
};

export default Maintenance;
