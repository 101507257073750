import { Col, Row } from "antd";
import React from "react";
import classes from "./TitlePage.module.css";
import ParcoursContext from "../../Context/ParcoursContext";

function TitlePage(props) {
  const { img, title } = props;
  const { brandProperties } = React.useContext(ParcoursContext);
  return (
    <Row className={classes.titleContainer}>
      <Col className={classes.assureIcon}>{img}</Col>
      <Col>
        <h3
          className={classes.title}
          style={{ color: brandProperties.secondColor }}
        >
          {title}
        </h3>
      </Col>
    </Row>
  );
}

export default TitlePage;
