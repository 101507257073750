// import maa from "../../../assets/logos/maa.svg";
// import cfdp from "../../../assets/logos/cfdp.svg";
// import cmma from "../../../assets/logos/cmma.svg";
// import acheel from "../../../assets/logos/acheel.svg";
// import etoile from "../../../assets/logos/etoile.svg";
// import prevoir from "../../../assets/logos/prevoir.svg";
// import mutuaide from "../../../assets/logos/mutuaide.svg";
// import fidelidate from "../../../assets/logos/fidelidate.svg";
// import alturgences from "../../../assets/logos/alturgences.svg";
// import ccmoMutuelle from "../../../assets/logos/ccmoMutuelle.svg";
// import aesioMutuelle from "../../../assets/logos/aesioMutuelle.svg";
// import assuranceOutremer from "../../../assets/logos/assuranceOutremer.svg";
import { Col, Layout, Row, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import ParcoursContext from "../../../Context/ParcoursContext";
import React from "react";

const { Footer } = Layout;

// const footerImages = [
//   {
//     src: fidelidate,
//     alt: "Fidelidate",
//   },
//   {
//     src: acheel,
//     alt: "Acheel",
//   },
//   {
//     src: aesioMutuelle,
//     acheel: "Aesio Mutuelle",
//   },
//   {
//     src: assuranceOutremer,
//     alt: "Assurance Outremer",
//   },
//   {
//     src: mutuaide,
//     alt: "Mutuaide",
//   },
//   {
//     src: cfdp,
//     alt: "CFDP",
//   },
//   {
//     src: cmma,
//     alt: "CMMA Assurance",
//   },
//   {
//     src: maa,
//     alt: "MAA",
//   },
//   {
//     src: etoile,
//     alt: "Etoile",
//   },
//   {
//     src: alturgences,
//     alt: "Alturgences",
//   },
//   {
//     src: ccmoMutuelle,
//     alt: "CCMO Mutuelle",
//   },
//   {
//     src: prevoir,
//     alt: "Prévoir",
//   },
// ];

const FooterLayout = () => {
  const {  brandProperties } = React.useContext(ParcoursContext);
  return (
    <div>
      {/* <Footer style={{ marginTop: "3rem" }}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="px-3">
            <img src={asLogoSm} alt="logo" />
            <h3
              className={classes.footerTitleAsSolution}
              style={{ marginTop: "1rem" }}
            >
              Plateforme d’échange sécurisée de données
            </h3>
            <p className={classes.footerText}>
              As Solutions se définit comme un courtier grossiste distributeur
              et créateur. Inspiré de l’esprit club et start-up, il mélange
              professionnalisme et expertise dans un esprit convivial.
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={7} xl={7} className="px-3">
            <h3 className={classes.footerTitle} style={{color:brandProperties.secondColor}}>Contactez-Nous</h3>
            <div className={classes.contact}>
              <h3 className={classes.footerSubtitle}>Adresse :</h3>
              <p className={classes.footerText}>
                15 Rue Bernoulli 75008 PARISFRANCE
              </p>
            </div>
            <div className={classes.contact}>
              <h3 className={classes.footerSubtitle}>Téléphone :</h3>
              <p className={classes.footerText}>01 84 80 23 89</p>
            </div>
            <div className={classes.contact}>
              <h3 className={classes.footerSubtitle}>E-mail :</h3>
              <p className={classes.footerText}>contact@as-solutions.fr</p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9} className="px-3">
            <h3 className={classes.footerTitle} style={{color:brandProperties.secondColor}}>Nos partenaires</h3>
            <Row>
              {footerImages.map((image, index) => (
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  key={index}
                  style={{ paddingInline: "1rem", paddingBlock: "0.25rem" }}
                >
                  <div >
                    <img
                      src={image?.src}
                      alt={image?.alt}
                      className={classes.grayImage}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Footer> */}
      <Footer
        style={{
          backgroundColor:brandProperties.principalColor ,
          padding: "5px 50px",
        }}
      >
        <Row className="align-items-center">
          <Col xs={24} sm={24} md={24} lg={12} xl={12}  >
            <Space>
              <FontAwesomeIcon
                icon={faXTwitter}
                style={{ color: "white", fontSize: "20px" }}
                className="mx-2"
              />
              <FontAwesomeIcon
                icon={faFacebookSquare}
                style={{ color: "white", fontSize: "20px" }}
                className="mx-2"
              />
              <FontAwesomeIcon
                icon={faLinkedin}
                style={{ color: "white", fontSize: "20px" }}
                className="mx-2"
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="d-flex justify-content-end">
            <h3
              className="d-flex flex-column flex-lg-row"
              style={{
                color: "white",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              <div className="my-2" style={{ marginRight: "10px" }}>
                © 2023 ASolutions{" "}
              </div>
              <ul className="list-unstyled my-2 ">
                <li>
                  <Space>
                    <a
                      href="https://as-solutions.fr/"
                      className="text-white"
                      target="_blank"
                      style={{ fontWeight: 500 }}
                      rel="noreferrer"
                    >
                      À Propos De Nous{" "}
                    </a>
                    <span>|</span>
                    <a
                      href="https://as-solutions.fr/mention-legales"
                      className="text-white"
                      target="_blank"
                      style={{ fontWeight: 500 }}
                      rel="noreferrer"
                    >
                      {" "}
                      Mentions légales{" "}
                    </a>
                    <span>|</span>
                    <a
                      href="https://as-solutions.fr/politique-de-confidentialite"
                      target="_blank"
                      className="text-white"
                      style={{ fontWeight: 500 }}
                      rel="noreferrer"
                    >
                      {" "}
                      Politique De Confidentialité{" "}
                    </a>
                  </Space>
                </li>
              </ul>
            </h3>
          </Col>
        </Row>
      </Footer>
    </div>
  );
};

export default FooterLayout;
