import axios from "axios";
import { notification } from "antd";
const apiHost = process.env.REACT_APP_APIURL;
const axiosInstance = axios.create({ baseURL: apiHost });

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.erreur) {
      notification.error({
        message: "Erreur",
        // duration: 0,
        // closable: true,
        duration: 5,
        description:
          response?.data?.error_message && response?.data?.message
            ? response?.data?.message
            : "Désolé, une erreur est survenue. Veuillez réessayer ultérieurement.",
      });

      return Promise.reject(response);
    } else if (response?.data?.error) {
      notification.error({
        message: "Erreur",
        // duration: 0,
        // closable: true,
        duration: 5,
        description:
          response?.data?.error_message && response?.data?.message
            ? response?.data?.message
            : "Désolé, une erreur est survenue. Veuillez réessayer ultérieurement.",
      });

      return Promise.reject(response);
    } else {
      return response;
    }
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response)
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("api_key"),
        };
        return axios
          .post(
            `${process.env.REACT_APP_GEOPROD_APIURL}login`,
            {},
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("token", res.data?.Acces_Token);
              axios.defaults.headers.common["idSession"] =
                localStorage.getItem("token");
              originalRequest.headers["idSession"] =
                localStorage.getItem("token");
              return axios(originalRequest);
            }
          });
      } else {
        notification.error({
          message: "Erreur",
          // duration: 0,
          // closable: true,
          duration: 5,
          description:
            error.response?.data?.message && error.response?.data?.error_message
              ? error.response?.data?.message
              : "Désolé, une erreur est survenue. Veuillez réessayer ultérieurement.",
        });

        return Promise.reject(error);
      }
    else return error;
  }
);

export default axiosInstance;
