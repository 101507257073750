import React from "react";
import classes from "./Layouts.module.css";
import { Outlet } from "react-router-dom";
import FooterLayout from "./Footer/FooterLayout";
import Header from "./Header/Header";
import ParcoursContext from "../../Context/ParcoursContext";
import { ConfigProvider, Spin } from "antd";
function Layouts() {

  const { loading } = React.useContext(ParcoursContext);

  return loading ? (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "#E00000" },
        components: {
          Spin: {
            colorPrimary: "#E00000",
          },
        },
      }}
    >
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large"  />
      </div>{" "}
    </ConfigProvider>
  ) : (
    <div>
      <Header />
      <div className={classes.container} >
        <Outlet />
      </div>
      <FooterLayout />
    </div>
  );
}

export default Layouts;
