import React, { useContext, useEffect, useState } from "react";

import classes from "./Home.module.css";
import {
  message,
  Steps,
  Form,
  ConfigProvider,
  Row,
  Col,
  FloatButton,
  notification,
  Flex,
} from "antd";
import frFR from "antd/locale/fr_FR";
import { ClearOutlined } from "@ant-design/icons";
// import CustomLoader from "../../components/CustomLoader/CustomLoader";
import UserInformation from "../UserInformation/UserInformation.js";
import Proposition from "../Proposition/Proposition.js";
import InformationsBancaires from "../InformationsBancaires/InformationsBancaires.js";
import ParcoursContext from "../../Context/ParcoursContext.js";
import { alertFunction } from "../../utils/alertFunction.js";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import SolidButton from "../../components/SolidButton/SolidButton.js";
import ModalSuccess from "../../components/ModalSuccess/ModalSuccess.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Home() {
  const {
    brandProperties,
    souscripteur,
    setCurrent,
    customerInformations,
    current,
    villeOptions,
    form,
    proposition,
    setOpenbackdrop,

  } = useContext(ParcoursContext);

  const steps = [
    {
      title: "Informations principales",
      content: <UserInformation form={form} />,
    },
    {
      title: "Tarification",
      content: <Proposition form={form} />,
    },
    {
      title: "Informations Bancaires",
      content: <InformationsBancaires form={form} />,
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));


  const [isModalOpen, setIsModalOpen] = useState(false);
  function isValidIBan(iban) {
    if (iban) {
      var regex = new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,27}/);
      let validIban = regex.test(iban.replace(/ /g, ""));
      return validIban;
    }
  }

  function isValidBic(bic) {
    if (bic) {
      var regex = new RegExp(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/);
      let validBic = regex.test(bic.replace(/ /g, ""));
      return validBic;
    }
  }
  function isValidNom(name) {
    var regex = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/);
    let validNom = regex.test(name);

    return validNom;
  }
  
 
  // const generateDevis = (type) => {
  //   let principalSous = souscripteur.find((e) => e.relationship_rank === 1);
  //   if (proposition.product_range) {
  //     notification.destroy();

  //     setOpenbackdrop(true);
  //     let customerInfo = [
  //       {
  //         number: 1,
  //         primary_subscriber: true,
  //         insured: true,
  //         beneficiary: false,
  //         birth_info: {
  //           date: dayjs(principalSous?.dn).format("DD/MM/YYYY"),
  //         },
  //         address: {
  //           street_name: principalSous?.adresse,
  //           zip_code: principalSous?.cp,
  //           city: principalSous?.ville,
  //         },
  //         job: {
  //           csp_insee: "Toutes",
  //           regime: "Régime général",
  //         },
  //         contract_information: {
  //           number: 476820,
  //           product:
  //             proposition?.product_range === "PJ VIE PRIVE"
  //               ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
  //               : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
  //         },
  //         relationship: "primary subscriber",
  //         relationship_rank: 1,
  //         identity: {
  //           use_name: principalSous?.nom,
  //           first_name: principalSous?.prenom,
  //         },
  //         phone: [
  //           {
  //             type: "Mobile",
  //             country_prefix: principalSous?.country_prefix,
  //             number: principalSous?.phone,
  //           },
  //         ],
  //       },
  //     ];
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/devis/generate_devis`,
  //         {
  //           id_opp: sessionStorage.getItem("SessionURLID"),
  //           document_produit: "protection_juridique_vie_prive",
  //           data_pricing: {
  //             option: [],
  //             third_app: {
  //               name: "ASolutions",
  //               id_external: 476820,
  //             },
  //             customer_information: customerInfo,
  //             contract_information: {
  //               effective_date: moment()
  //                 .add(1, "month")
  //                 .startOf("month")
  //                 .format("DD/MM/yyyy"),
  //               mode_splitting: "Mensuel",
  //             },
  //           },
  //           data_fields: {
  //             email: souscripteur[0]?.mail,
  //             civilite: souscripteur[0]?.civility === "Monsieur" ? "Mr" : "Mme",
  //             opt_premium:
  //               proposition?.product_range === "PJ VIE PRIVE" ? "Non" : "Oui",
  //           },
  //         },
  //         {
  //           headers: {
  //             apiKey: localStorage.getItem("api_key"),
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         if (type === "exporter") {
  //           window.open(response?.data?.devis, "_blank");
  //         }
  //         if (type === "mail") {
  //           toggle();
  //         }
  //         setPdf(response?.data?.devis);
  //         setOpenbackdrop(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setOpenbackdrop(false);
  //       });
  //   } else {
  //     alertFunction("Vous devez choisir une proposition.", "error");
  //   }
  // };
  const ContractFillHandler = async (
    num_contrat,
    file_url_bulletin,
    file_url_certif,
    id_affaire,
    mandat
  ) => {
    var files = [];
    let listProspects = [];
    if (souscripteur.length > 1) {
      souscripteur
        .filter((element) => element.relationship_rank >= 2)
        ?.forEach((element) => {
          listProspects = [
            ...listProspects,
            {
              name: element?.nom,
              surname: element?.prenom,
              DN: dayjs(element.dn).format("DD/MM/YYYY"),
              id: "",
              id_opp: "",
              campagne_id: "",
              post: "",
              interlocuteur: "",
              ville: "",
              situation: element?.relationship === "married" ? "married" : "",
              users_nom: "",
              users_prenom: "",
              adresse_mail: "",
              civilite: element?.civility,
              prospects_civ: "",

              prospects_situation: "",
              nb_enfants: "",
              adresses_adresse: "",
              streetNumber: "",
              CP: "",
              streetName: "",
              adresses_ville: "",
              complement_adr: "",
              tel: "",
              tel2: "",
              mobile: "",
              first_campagne: "",
              description: "",
              etat: "",
              lat: "",
              latitude: null,
              longitude: null,
              commercieux_id: "",
              commentaire: "",
              geom: "",
              tags: [],
              lng: "",
              date_naiss: "",
              collab: "",
              id_pros: "",
              id_camp: "",
              coment_plus: "",
              code: "",
              rue: "",
              numero: "",
              complement: "",
              mail: "",
              nb_enfant: "",
              infcomp: "",
              relation:
                element.relationship === "married" ? "Conjoint" : "Descendant",
              pays: "",
              invalidForms: [],
              deleted: false,
              information_bancaire: {
                id: "",
                titulaire_compte: "",
                iban: "",
                bic_swift: "",
                type_iban: "",
                nom_banque: "",
              },
              prospects_id: "",
            },
          ];
        });
    }

    files = [
      ...files,
      {
        url: file_url_bulletin,
        file_index: 1,
        file_name: "Contrat",
        id_type_doc: 25,
      },
      {
        url: mandat,
        file_index: 2,
        file_name: "Mandat Sepa",
        id_type_doc: 11,
      },
    ];
    let formData = {
      id_opp: sessionStorage.getItem("SessionURLID"),
      num_contrat: num_contrat?.replace("AE/FS", "02ODC228447/"),
      id_affaire: id_affaire,
      files: files,
      members: [
        {
          firstname: souscripteur[0]?.nom,
          lastname: souscripteur[0]?.prenom,
          email: souscripteur[0]?.mail,
          phone: souscripteur[0]?.country_prefix + souscripteur[0]?.phone,
          fileObjects: [
            {
              file_index: 1,
              page: parseInt(process.env.REACT_APP_SIGNATURE_PAGE),
              position: process.env.REACT_APP_SIGNATURE_POSITION_CONTRAT,
            },
            {
              file_index: 2,
              page: 1,
              position: process.env.REACT_APP_SIGNATURE_MANDAT_POSITION,
            },
          ],
        },
      ],
    };

    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/yousign/e-yousign_v2`,
        formData,
        {
          headers: {
            idSession: localStorage.getItem("api_key"),
            // "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        // navigate(
        //   `/?partnership_id=${localStorage.getItem(
        //     "api_key"
        //   )}/Success`
        // );
        setOpenbackdrop(false);
        setIsModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setOpenbackdrop(false);
      });
  };
  const subscribeHandler = async (signature_electronique) => {
    setOpenbackdrop(true);
    notification.destroy();
    axios
      .post(
        `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/subscription/subscribe_v2`,
        {
          call_source: "parcours",
          document_produit: "protection_juridique_vie_prive",
          id_opp: sessionStorage.getItem("SessionURLID"),
          ligne_produit: "14",
          fileType: "url",
          information_bancaire: {
            bic_swift: souscripteur[0]?.bicremboursement
              ? souscripteur[0]?.bicremboursement
              : souscripteur[0]?.bic,
            iban: souscripteur[0]?.ibanremboursement
              ? souscripteur[0]?.ibanremboursement
              : souscripteur[0]?.iban,
            type_iban: "prestation",
            titulaire_compte: `${souscripteur[0]?.nom} ${souscripteur[0]?.prenom}`,
            nom_banque: souscripteur[0]?.banqueRemboursement
              ? souscripteur[0]?.banqueRemboursement
              : souscripteur[0]?.banquePrelevement,
          },
          pricing: {
            third_app: {
              name: "ASolutions",
              id_external: 476820,
            },
            customer_information: customerInformations,
            contract_information: {
              effective_date: moment()
                .add(1, "month")
                .startOf("month")
                .format("DD/MM/yyyy"),
              mode_splitting: "Mensuel",
              signature_date: moment().format("DD/MM/yyyy"),
              payment_information: {
                mode_splitting: "Mensuel",
                withdrawal_day: souscripteur[0]?.jourprelevement,
                payment_mode: "Direct Debit",
                bic: souscripteur[0]?.bic,
                iban: souscripteur[0]?.iban,
                nom_banque: souscripteur[0]?.banquePrelevement,
                nom_prenom_debiteur: souscripteur[0]?.nomDebiteur,
              },
            },
          },
        },
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((result) => {
        ContractFillHandler(
          result.data?.num_contrat,
          result.data?.pdf,
          result.data?.pdf_certif,
          result.data?.id_affaire,
          result?.data?.mandat
        );
      })

      .catch((err) => {
        setOpenbackdrop(false);
        console.log(err);
      });
  };
  const next = () => {
    if (current === 1) {
      let errorMessage = "";
      let finaltest = souscripteur.every((val, index) => {
        let test = Object.keys(val).every((key) => {
          if (
            (key === "adresse" ||
              key === "civility" ||
              key === "country_prefix" ||
              key === "cp" ||
              key === "mail" ||
              key === "nom" ||
              key === "phone" ||
              key === "ville" ||
              key === "prenom") &&
            val[key].replace(/ /g, "") === ""
          ) {
            errorMessage = "Veuillez remplir tous les champs obligatoires.";
            return false;
          } else if (key === "dn" && !val[key]) {
            errorMessage = "Veuillez remplir tous les champs obligatoires.";
            return false;
          } else if (key === "mail") {
            var regex = new RegExp(/^[\w-.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/);
            let validEmail = regex.test(val[key].replace(/ /g, ""));
            errorMessage = "Veuillez vérifier votre email.";
            return validEmail;
          } else if (key === "nom" && val?.relationship_rank === 1) {
            if (isValidNom(val[key].replace(/ /g, "")) === false) {
              errorMessage = "Veuillez vérifier votre nom.";
            }
            return isValidNom(val[key].replace(/ /g, ""));
          } else if (key === "nom" && val?.relationship_rank === 2) {
            if (isValidNom(val[key].replace(/ /g, "")) === false) {
              errorMessage = "Veuillez vérifier le nom de votre conjoint.";
            }
            return isValidNom(val[key].replace(/ /g, ""));
          } else if (key === "nom" && val?.relationship_rank >= 11) {
            if (isValidNom(val[key].replace(/ /g, "")) === false) {
              errorMessage = `Veuillez vérifier le nom de l'enfant ${
                val?.relationship_rank - 10
              }.`;
            }
            return isValidNom(val[key].replace(/ /g, ""));
          } else if (key === "prenom" && val?.relationship_rank === 1) {
            if (isValidNom(val[key].replace(/ /g, "")) === false) {
              errorMessage = "Veuillez vérifier votre prénom.";
            }
            return isValidNom(val[key].replace(/ /g, ""));
          } else if (key === "prenom" && val?.relationship_rank === 2) {
            if (isValidNom(val[key].replace(/ /g, "")) === false) {
              errorMessage = "Veuillez vérifier le prénom de votre conjoint.";
            }
            return isValidNom(val[key].replace(/ /g, ""));
          } else if (key === "prenom" && val?.relationship_rank >= 11) {
            if (isValidNom(val[key].replace(/ /g, "")) === false) {
              errorMessage = `Veuillez vérifier le prénom de l'enfant ${
                val?.relationship_rank - 10
              }.`;
            }
            return isValidNom(val[key].replace(/ /g, ""));
          } else if (key === "country_prefix") {
            let prefReg = new RegExp(/^\+\d+$/);
            errorMessage =
              "Veuillez vérifier le préfixe de votre numéro de téléphone.";
            return prefReg.test(val["country_prefix"].replace(/ /g, ""));
          }

          return true;
        });
        return test;
      });

      if (finaltest) {
        setCurrent(current + 1);

        sessionStorage.setItem("souscripteur", JSON.stringify(souscripteur));
        sessionStorage.setItem("villeOptions", JSON.stringify(villeOptions));
        notification.destroy();
      } else {
        alertFunction(errorMessage, "error");
      }
    } else if (current === 2) {
      if (proposition.product_range) {
        notification.destroy();
        setCurrent(current + 1);
      } else {
        alertFunction("Vous devez choisir une proposition.", "error");
      }
    } else if (current === 3) {
      if (!souscripteur[0].bic) {
        alertFunction("Veuillez remplir votre BIC.", "error");
      } else if (!souscripteur[0].iban) {
        alertFunction("Veuillez remplir votre IBAN.", "error");
      } else if (
        isValidBic(souscripteur[0].bic) === false ||
        isValidBic(souscripteur[0]?.bicremboursement) === false
      ) {
        alertFunction("Veuillez vérifier votre BIC.", "error");
      } else if (
        isValidIBan(souscripteur[0].iban) === false ||
        isValidIBan(souscripteur[0].ibanremboursement) === false
      ) {
        alertFunction("Veuillez vérifier votre IBAN.", "error");
      } else if (
        !souscripteur[0]?.bicremboursement &&
        souscripteur[0].ibanremboursement
      ) {
        alertFunction("Veuillez remplir votre BIC remboursement.", "error");
      } else if (
        souscripteur[0]?.bicremboursement &&
        !souscripteur[0].ibanremboursement
      ) {
        alertFunction("Veuillez remplir votre IBAN remboursement.", "error");
      } else {
        subscribeHandler();
      }
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    sessionStorage.setItem("current", JSON.stringify(current));
  }, [current]);

  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: brandProperties.principalColor },
        components: {
          Collapse: {
            colorBorder: "#FFFFFF",
          },
          Spin: {
            colorPrimary: brandProperties.principalColor,
          },
          Switch: {
            colorPrimary: brandProperties.secondColor,
            colorPrimaryHover: brandProperties.secondColor,
            colorTextQuaternary: brandProperties.secondColor,
            lineHeight: 1.75,
          },
          Steps: {
            colorPrimary: brandProperties.principalColor,
            colorText: brandProperties.principalColor,
            fontFamily: "Inter, sans-serif",
            controlItemBgActive: "rgb(223, 223, 223)",
            colorBorderSecondary: "rgb(223, 223, 223)",
            colorTextLabel: "rgb(255, 255, 255)",
            colorSplit: "rgb(223, 223, 223)",
            colorFillContent: "rgb(223, 223, 223)",
            iconFontSize: 18,
            iconSize: 34,
            fontSizeLG: 17,
          },
        },
      }}
      locale={frFR}
    >
      <div>
        <ModalSuccess open={isModalOpen} form={form} />

       
        <Form
          noValidate
          form={form}
          layout="vertical"
          onFinishFailed={() => {
            message.error("Veuillez vérifier tous les champs !");
          }}
          onFinish={() => {
            next();
          }}
          onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          className={classes.formContainer}
        >
          <Flex justify="center">
            <Steps
              labelPlacement="vertical"
              style={{
                paddingBlock: "3.5rem",
                fontWeight: "600",
                width: "70%",
              }}
              className={classes.stepper}
              current={current - 1}
              onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
              items={items}
            />
          </Flex>

          <div>{steps[current - 1]?.content}</div>

      
          <Row style={{}}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              className={classes.colBtn}
            >
              <SolidButton
                onClick={() => prev()}
                disabled={current === 1 ? true : false}
                text={
                  <>
                    <FontAwesomeIcon
                      style={{ marginRight: "0.5rem" }}
                      icon={faArrowLeft}
                    />
                    Précédent
                  </>
                }
                styles={{
                  border: `1px solid ${brandProperties.secondColor}`,
                  width: "10rem",
                  fontWeight: "600",
                }}
                size="large"
                colorPrimary="#F7F6FA"
                textColor={brandProperties.secondColor}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              className={classes.colBtn}
            >
              <SolidButton
                onClick={next}
                text={
                  <>
                    Suivant
                    <FontAwesomeIcon
                      style={{ marginLeft: "0.5rem" }}
                      icon={faArrowRight}
                    />
                  </>
                }
                styles={{
                  border: `1px solid ${brandProperties.principalColor}`,
                  width: "10rem",
                  fontWeight: "600",
                }}
                size="large"
                colorPrimary={brandProperties.principalColor}
                type="submit"
                textColor="#FFFFFF"
              />
            </Col>
          </Row>
        </Form>
        <FloatButton
          type="primary"
          icon={<ClearOutlined />}
          tooltip={<div>Remise à zero</div>}
          onClick={() => {
            // sessionStorage.clear();
            // window.location.reload();
            sessionStorage.clear();
            window.location.replace(
              `/?partnership_id=${localStorage.getItem("api_key")}`
            );
          }}
        />
      </div>
    </ConfigProvider>
  );
}

export default Home;
