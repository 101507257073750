import React from "react";
import { Backdrop } from "@mui/material";
import classes from "./InformationsBancaires.module.css";
import ParcoursContext from "../../Context/ParcoursContext";
import InputComponent from "../../components/Input/InputComponent";
import { Row, Spin } from "antd";
import TitlePage from "../../components/TitlePage/TitlePage";
function InformationsBancaires() {
  const {
    souscripteur,
    customerInformations,
    setCustomerInformations,

    proposition,
    brandProperties,
    openbackdrop,
  } = React.useContext(ParcoursContext);

  const rows = [
    [
      {
        label: "Adresse",
        value: souscripteur[0]?.adresse,
        disabled: true,
        name: "adresse",
        type: "text",
      },
    ],
    [
      {
        label: "Ville",
        value: souscripteur[0]?.ville,
        disabled: true,
        name: "ville",
        type: "text",
      },
      {
        label: "Code postal",
        value: souscripteur[0]?.cp,
        disabled: true,
        name: "cp",
        type: "text",
      },
    ],
    [
      {
        label: "BIC prélèvement",
        value: souscripteur[0]?.bic,
        disabled: false,
        name: "bic",
        type: "text",
        required: true,
      },
      {
        label: "IBAN prélèvement",
        value: souscripteur[0]?.iban,
        disabled: false,
        name: "iban",
        type: "text",
        required: true,
      },
    ],
    [
      {
        label: "BIC remboursement",
        value: souscripteur[0]?.bicremboursement,
        disabled: false,
        name: "bicremboursement",
        type: "text",
      },
      {
        label: "IBAN remboursement",
        value: souscripteur[0]?.ibanremboursement,
        disabled: false,
        name: "ibanremboursement",
        type: "text",
      },
    ],
    [
      {
        label: "Jour de prélèvement",
        value: souscripteur[0]?.jourprelevement,
        disabled: false,
        name: "jourprelevement",
        type: "select",
      },
      {
        label: "Nom de la banque prélèvement",
        value: souscripteur[0]?.banquePrelevement,
        disabled: false,
        name: "banquePrelevement",
        type: "text",
      },
    ],
    [
      {
        label: "Nom de la banque remboursement",
        value: souscripteur[0]?.banqueRemboursement,
        disabled: false,
        name: "banqueRemboursement",
        type: "text",
      },
    ],
    [
      {
        label: "Nom et Prénom du débiteur",
        value: souscripteur[0]?.nomDebiteur,
        disabled: false,
        type: "text",
        name: "nomDebiteur",
      },
    ],
  ];

  React.useEffect(() => {
    let id_pros = JSON.parse(
      sessionStorage.getItem("_subs_tags")
    )?.id_prospect_md5_with_givenTgas;

    // const id = new URLSearchParams(window.location.search).get("IdAdhesion");
    // if (id) {
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/session_url/get_object_from_session_url/${id}`
    //     )
    //     .then((res) => {
    //       if (res.data?.error === true) {
    //         // Swal.fire({
    //         //   icon: 'error',
    //         //   title: 'Erreur',
    //         //   text: res.data?.message.message,
    //         // });
    //         alertFunction(res.data?.message.message, "error");
    //         setOpenbackdrop(false);
    //       }
    //       setProposition(res.data?.proposition);
    //       setCustomerInformations(res.data?.customerInfo);
    //       setSouscripteur(res.data?.souscripteur);
    //       sessionStorage.setItem("SessionURLID", res.data?.id_opp);
    //       sessionStorage.setItem(
    //         "_subs_tags",
    //         JSON.stringify(res.data?._subs_tags)
    //       );
    //       setVilleOptions(res.data.villeOptions);
    //       setFree(false);
    //       setCurrent(res.data.num_step)
    //       // setNomDebiteur(
    //       //   res.data?.souscripteur[0]?.nom +
    //       //     " " +
    //       //     res.data?.souscripteur[0]?.prenom
    //       // );
    //     })
    //     .catch((err) => {
    //       // Swal.fire({
    //       //   icon: 'error',
    //       //   title: 'Erreur',
    //       //   text: err,
    //       // });
    //       alertFunction(err, "error");
    //       setOpenbackdrop(false);
    //     });
    // } else {
    let newCustomerInformations = [];
    customerInformations?.forEach((element, index) => {
      var email = "";
      if (element?.relationship_rank === 1) {
        email = souscripteur[0]?.mail;
      } else if (element?.relationship_rank === 2) {
        email = "conjoint" + souscripteur[0]?.mail;
      } else {
        email =
          "Enfant" + (element?.relationship_rank - 10) + souscripteur[0]?.mail;
      }
      newCustomerInformations.push({
        number: element?.relationship_rank,
        primary_subscriber: element?.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: element.birth_info.date,
        },
        address: {
          street_name: souscripteur[0]?.adresse,

          zip_code: souscripteur[0]?.cp,
          city: souscripteur[0]?.ville,
          code_insee: souscripteur[0]?.code_insee,
        },
        id_pros:
          element?.relationship_rank === 1
            ? Object.keys(id_pros).find((key) => id_pros[key] === "Prospect")
            : element.relationship_rank === 2
            ? Object.keys(id_pros).find((key) => id_pros[key] === "Conjoint")
            : Object.keys(id_pros).find(
                (key) =>
                  id_pros[key] === "Enfant" + (element?.relationship_rank - 10)
              ),
        job: {
          csp_insee: "Toutes",
          regime: "Régime général",
        },
        contract_information: {
          product:
            proposition?.product_range === "PJ VIE PRIVE"
              ? [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT_SIMPLE]
              : [process.env.REACT_APP_PROTECTION_JURIDIQUE_CONTRACT],
        },
        relationship: element?.relationship,
        relationship_rank: element?.relationship_rank,
        mail: email,
        identity: {
          civility: souscripteur[index]?.civility,
          use_name: souscripteur[index]?.nom,
          first_name: souscripteur[index]?.prenom,
        },
        phone: [
          {
            type: "Mobile",
            country_prefix: souscripteur[0]?.country_prefix,
            number: souscripteur[0]?.phone,
          },
        ],
      });
    });
    setCustomerInformations(newCustomerInformations);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ paddingInline: "5%" }}>
        <TitlePage img={<i className="fa-duotone fa-wallet" style={{fontSize:"1.75rem", color:brandProperties.secondColor}}></i>} title="Informations Bancaires" />

        <Row gutter={16}>
          {rows.map((col, index) => {
            return col.map((val, idx) => {
              return (
                <InputComponent
                  key={idx}
                  label={val.label || ""}
                  value={val.value || ""}
                  disabled={val.disabled || ""}
                  name={val.name || ""}
                  type={val.type || ""}
                  required={val.required || ""}
                />
              );
            });
          })}{" "}
        </Row>

        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openbackdrop}
        >
          <div className={classes.loaderContainer}>
            <div
              style={{
                paddingBlock: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" />
            </div>{" "}
            <h2>Veuillez patienter un instant.</h2>
          </div>
        </Backdrop>
      </div>
    </>
  );
}

export default InformationsBancaires;
