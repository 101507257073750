import {  Col, Modal, Row } from "antd";
import React from "react";
import classes from "./ModalSuccess.module.css";
import iconAssure from "../../assets/icons/Assure.svg";
import ParcoursContext from "../../Context/ParcoursContext";
import SolidButton from "../SolidButton/SolidButton";
const ModalSuccess = (props) => {
    const { open } = props;
  const { souscripteur, brandProperties } = React.useContext(ParcoursContext);
  const id = localStorage.getItem("api_key");
  return (
    <Modal open={open} className={classes.ModalSuccess} closeIcon={false} footer={null}>
      <Row className={classes.modalRow}>
        <Col className={classes.felicitation}>
          <span>
            Félicitation{" "}
            {souscripteur[0]?.civility === "Monsieur" ? "Mr" : "Mme"}{" "}
            {souscripteur[0].nom} {souscripteur[0].prenom}
          </span>
        </Col>
        <Col className={classes.colIcon}>
          <img src={iconAssure} alt="" />
        </Col>
        <Col className={classes.souscriptionTerminée}>
          Votre souscription est terminée
        </Col>
        <Col>
          <SolidButton
            onClick={() => {
              sessionStorage.clear();
              id
                ? (window.location = `/?partnership_id=${id}`)
                : (window.location = "/");
            }}
            text="Une autre souscription"
            styles={{ border: `1px solid ${brandProperties.principalColor}`, marginBlock: "2rem", fontWeight:"600" }}
            size="large"
            colorPrimary={brandProperties.principalColor}
            textColor="#FFFFFF"
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalSuccess;
