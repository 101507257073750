import React, { useEffect } from "react";
import classes from "./PreviewEmail.module.css";

import ParcoursContext from "../../../../Context/ParcoursContext";
function PreviewEmail() {
  const { templateDevis, editorMessage, proposition, brandProperties } =
    React.useContext(ParcoursContext);
  const [previewTemplate, setPreviewTemplate] = React.useState("");

  useEffect(() => {
    const prospect = JSON.parse(sessionStorage.getItem("souscripteur"))?.find(
      (e) => e?.relationship_rank === 1
    );
    let temp = templateDevis.replace(
      "*|FNAME|*",
      prospect?.nom ? (prospect?.nom).trim() : ""
    );
    temp = temp.replace(
      "*|LNAME|*",
      prospect?.prenom ? (prospect?.prenom).trim() : ""
    );

    temp = temp.replace(
      "[NOM GARANTIE]",
      proposition?.product_range === "PJ VIE PRIVE + OPTION"
        ? "Pj vie privée premium"
        : "Pj vie privée"
    );
    temp = temp.replace("[--urlDevis--]", "https://as-solutions.fr/");
    setPreviewTemplate(temp);
    // eslint-disable-next-line
  }, [editorMessage]);
  return (
    <div style={{ minHeight: "100%" }}>
      <h4 style={{ marginBlock: "2rem", color: brandProperties.secondColor }}>
        {" "}
        Visuel
      </h4>
      <div className={classes.contain}>
        <div dangerouslySetInnerHTML={{ __html: previewTemplate }} />
      </div>
    </div>
  );
}

export default PreviewEmail;
