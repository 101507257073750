import React from "react";
import Souscripteur from "./Souscripteur/Souscripteur";
import classes from "./UserInformation.module.css";
import FamilyComposition from "./FamilyComposition/FamilyComposition";

function UserInformation() {
  return (
    <>
      <section className={classes.container}>
        <Souscripteur />
        <FamilyComposition />
      </section>
    </>
  );
}

export default UserInformation;
